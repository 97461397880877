<template>
  <div
    :class="'languages' + ' ' + this.$route.name"
    v-if="showIcon.includes(this.$route.name)"
  >
<!--    语言包图标-->
<!--    <i-->
<!--      @click="changeLanguages($i18n.locale)"-->
<!--      :class="'iconfont ' + getIconClass($i18n.locale) + ' ' + this.$route.name"-->
<!--    ></i>-->
  </div>
</template>
<script>
export default {
  name: "languages",
  data() {
    return {
      showIcon: ["welcome", "login", "register"],
    }
  },
  methods: {
    getIconClass(locale) {
      if (locale == "en_US") return "icon-a-zhongyingwenyingwen"
      if (locale == "zh_CN") return "icon-a-zhongyingwenzhongwen3"
    },
    changeLanguages(locale) {
      if (locale == "en_US") {
        window.localStorage.setItem("lang", "zh_CN")
        this.$i18n.locale = "zh_CN"
      }
      if (locale == "zh_CN") {
        window.localStorage.setItem("lang", "en_US")
        this.$i18n.locale = "en_US"
      }
    },
  },
}
</script>
<style lang="less" scoped>
.languages {
  position: absolute;
  top: 10px;
  right: 10px;
  .welcome,
  .login,
  .register {
    font-size: 5rem;
    color: #0fa47f;
  }
  .home {
    font-size: 3rem;
    color: #fff;
  }
}
.home {
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
}
</style>
