<template>
  <div class="welcome-container">
    <languages></languages>
    <div class="logo-container">
      <img class="logo" src="../../../public/logo1.png" />
    </div>
    <div class="text-container">
      <h1>{{$t('让每个人都用上人工智能')}}</h1>
      <!-- <p>{{$t('简单好用的人工智能')}}</p> -->
    </div>
    <div class="button-container">
      <button class="login-button" @click="toLogin()">{{$t('登录')}}</button>
      <button class="signup-button" @click="toSignUp()">{{$t('注册')}}</button>
    </div>
  </div>
</template>
<script>
import languages from "../../components/languages/index.vue"
import { recordVisit } from "../../config/api.js";

  export default {
    name: "Welcome",
    components:{languages},
    mounted() {
      this.visited();
    },
    methods: {
      visited() {
        recordVisit().then(res => {});
      },
      toLogin() {
        this.$router.push({
          name: "login"
        });
      },
      toSignUp() {
        this.$router.push({
          name: "register"
        });
      },
    },
  };
</script>
<style lang="less" scoped>

  .welcome-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
    text-align: center;
  }

  .logo-container {
    margin-bottom: 2rem;
  }

  .logo {
    width: 100px;
    height: 100px;
    border-radius: 12%;
  }

  .text-container {
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 3rem;
    color: #fff;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
    color: #fff;
  }

  .button-container {
    display: flex;
    justify-content: center;
  }

  .login-button,
  .signup-button {
    margin: 0 1rem;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }

  .login-button {
    background-color: var(--theme-color);
    color: white;
  }

  .signup-button {
    background-color: white;
    color: var(--theme-color);
    border: 2px solid var(--theme-color);
  }

  .login-button:hover,
  .signup-button:hover {
    background-color: var(--theme-color);
    color: white;
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.2rem;
    }

    .logo-container {
      margin-top: 5rem;
    }

    .login-button,
    .signup-button {
      padding: 0.8rem 1.5rem;
      font-size: 1rem;
    }
  }
</style>
